import $ from "jquery";

function ornamentPlacement(element, bottomOrnament) {
  let distanceFromTop;
  let isMobile = window.matchMedia("(max-width: 767px)").matches;
  const bottomOrnamentHeight = bottomOrnament.height();
  const blockHeight = element.height();
  const blockYpos = element.offset().top;
  
  isMobile
    ? (distanceFromTop =
        blockYpos + blockHeight - bottomOrnamentHeight / 2 + 25)
    : (distanceFromTop = blockYpos + blockHeight - bottomOrnamentHeight + 15);

  let ornamentParams = {
    position: "absolute",
    top: distanceFromTop + "px",
    width: "100vw",
    left: "-15vw",
    opacity: "1",
    zIndex:1,
  };

  if (!isMobile) {
    ornamentParams.width = "48vw";
    ornamentParams.left = "8.14vw";
  }
  bottomOrnament.css(ornamentParams);
}

function updateOrnamentPosition() {
  $(".mg-block--feature-page.is-style-feature-page-var-2").each(function () {
    const element = $(this);
    const bottomOrnament = element.next(".feature-page-ornament-2");
    if (bottomOrnament.length) {
      ornamentPlacement(element, bottomOrnament);
    }
  });
}

$(document).ready(function () {
  $(".mg-block--feature-page.is-style-feature-page-var-2").each(function () {
    const element = $(this);
    const bottomOrnament = element.find(".feature-page-ornament-2");
    if (bottomOrnament.length) {
      ornamentPlacement(element, bottomOrnament);
      bottomOrnament.insertAfter(element);
    }
  });

  $(window).on("resize", updateOrnamentPosition);
});
